.caff_light {
  --background-color: #fff;
  --shade-background: #eaeaea;
  --text-color: #333;
  --input-text-color: #333;
  --primary-color: #14805e;
  --secondary-color: gray;
  --light-league-bg: #f7f8ff;
  --border-color: silver;
  --hover-color: lightgray;
  --active-color: red;
  --red: red;
  --green: green;
  --success-bg: #c5e1a5;
  --success-color: #05800e;
  --error-bg: red;
  --error-color: white;
  --white: white;
  --betslip-bg: #e4e4e4;
  --betsli-color: #333;
  --down-nav-color: #fff;
  --loader-border-color: rgba(0, 0, 0, 0.1);
  --collapse-content-text: #333;
  --score-color: #333;
  --sub-nav: #fbfbfb;
  --tab-color: #88cf8d;
  --main-nav-color: #333;
  --common-white: #fbfbfb;
}
.caff_dark {
  --background-color: #444;
  --shade-background: #505050;
  --text-color: #f5f5f5;
  --input-text-color: #333;
  --primary-color: #14805e;
  --light-primary-color: #4acfa5;
  --secondary-text-color: #ffe600;
  --secondary-color: darkgray;
  --light-league-bg: #13141d;
  --primary-border-color: #5e5e5e;
  --border-color: rgb(0, 0, 0);
  --shade-border-color: #636363;
  --hover-color: gray;
  --active-color: red;
  --red: red;
  --green: green;
  --light-green: rgb(9, 185, 119);
  ---bg: #c5e1a5;
  --success-color: #05800e;
  --white: white;
  --betslip-bg: #e4e4e4;
  --betslip-color: #333;
  --down-nav-color: #b1bbbf;
  --loader-border-color: #333;
  --collapse-content-text: #cbcbcb;
  --score-color: #c5c5c5;
  --sub-nav: #3c3c3c;
  --tab-color: #4c4c4c;
  --main-nav-color: #fbfbfb;
  --common-white: #fbfbfb;
}

html,
body {
  background-color: var(--background-color);
  color: var(--text-color);
  height: 100%;
  min-height: 100%;
  font-weight: 300;
}
body a {
  color: var(--text-color);
}

body #themeWrapper {
  background-color: var(--background-color);
  color: var(--text-color);
  box-sizing: border-box;
}
/* sprite background */
.sprite {
  background-image: url("./assets/sprite/sports_sprite.png");
  background-repeat: no-repeat;
  height: 61px;
  width: 62px;
  transform: scale(0.52);
  /* transform-origin: top left; */
}
/* no icons */
li.nav-item.no-sport-icon.nav-item {
  display: none;
}
._spid22,
._spid3 {
  background-position: -38px -4582px;
}
._spid4 {
  background-position: -24px -371px;
}
._spid5 {
  background-position: -24px -710px;
}
._spid7 {
  background-position: -26px -2989px;
}
._spid13 {
  background-position: -30px -4697px;
}
._spid18 {
  background-position: -21px -8007px;
}
._spid56 {
  background-position: -25px -1399px;
}
/* formula one */
._spid6 {
  background-position: -25px -7092px;
}
/* skiing */
._spid9 {
  background-position: -24px -2191px;
}

/* cycling */
._spid10 {
  background-position: -17px -1060px;
}
._spid11 {
  background-position: -27px -5155px;
}
._spid12 {
  background-position: -18px -7434px;
}
/* handball */
._spid16 {
  background-position: -27px -5496px;
}
._spid23 {
  background-position: -27px -6980px;
}
._spid24 {
  background-position: -29px -597px;
}
/*motorbikes*/
._spid40 {
  background-position: -16px -946px;
}
/* darts */
._spid34 {
  background-position: -21px -3559px;
}
/* rugby league union */
._spid31,
._spid32 {
  background-position: -28px -5041px;
}
/* biathlon */
._spid64 {
  background-position: -26px -5838px;
}
._spid100 {
  background-position: -19px -9379px;
}
/* badminton */
._spid44 {
  background-position: -25px -5612px;
}

/* snooker */
._spid33 {
  background-position: -18px -2879px;
}
/* for main front sports */
.sports_top_text {
  display: flex;
  /* background: var(--shade-background); */
  padding: 5px;
}

.sports_top_text > div {
  flex: 1;
}
.sports_event-name {
  color: var(--secondary-text-color);
  text-align: left;
  border-left: 2px solid var(--primary-color);
  padding-left: 5px;
}
a.carousel-control-prev {
  min-height: 90px;
}
.sports_event-name svg {
  margin-top: -4px;
}
.sports_region a {
  color: var(--light-primary-color);
}
.sports_region {
  text-align: right;
}
.sport_timer_wrapper {
  display: flex;
  margin-top: 5px;
  color: var(--score-color);
  font-size: 12px;
}

.sport_timer_text {
  margin-right: 40px;
}
.sport__headers {
  color: var(--text-color);
}

/* header */
.titleContainer {
  background-color: #262828;
  color: var(--white);
}

.titleContainer a {
  color: var(--white);
}

img._primary-logo {
  height: 32px;
}

.sport-nav-list {
  display: flex;
  flex-wrap: wrap;
}

.sport-nav-item {
  margin: 0px;
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 8px;
  border-bottom: 1px solid #2b2929;
}
.sport-nav-item span:last-child {
  margin-left: auto;
}
.sport-nav-name {
  margin-left: 5px;
}
.active-favorite {
  color: var(--secondary-text-color);
}
a.sport-nav-item.navactive {
  background: var(--shade-background);
}
.mobile-sport-nav-list {
  width: 100%;
}
.header-sub-navigation .navactive {
  color: var(--secondary-text-color);
}

button._nav_icon svg {
  color: var(--light-primary-color);
  max-width: 28px;
}

/* for bs form */
.full-width {
  width: 100%;
}

/* for front sports nav sports items scroll */

.nav-tabs {
  display: flex;
  flex-wrap: nowrap !important;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}
._event-lists .nav-tabs .nav-item {
  border-right: 1px solid #363636 !important;
  box-shadow: 1px 0px 1px rgb(87 87 87 / 50%);
  text-align: center;
  border: none;
}
.row.layout-content-container ._mainNav {
  padding: 0;
}

.nav-tabs::-webkit-scrollbar {
  /* For Chrome, Safari and Opera */
  display: none;
}
.nav-tabs li a {
  font-size: 14px;
  padding: 5px;
  min-width: 80px;
  text-align: center;
}
a.nav-link span {
  display: block;
}
._event-lists a.active.nav-link {
  border-radius: 0px;
  border: none;
  background: var(--tab-color);
  color: #ffe600;
}
._event-lists a {
  transition: none;
}
._event-lists .nav-tabs .nav-link {
  border: none;
  padding-top: 0px;
}
._event-lists ul.nav-tabs.nav.nav-tabs {
  border-bottom: 1px solid #363636;
}
._sp-name {
  display: flex;
  width: 100%;
}
._sp-text,
._scount {
  white-space: nowrap;
}
span._sp-count {
  margin-top: -8px;
}
.sport-icon-container {
  line-height: 0px;
  padding-bottom: 5px;
  text-align: center;
  display: table;
  margin: 0 auto;
}

/*mybets*/
a._navigate-my-bets {
  margin-right: 10px;
  font-weight: bold;
  font-size: 13px;
}
button.slipBtn {
  width: 100%;
}
._slip_total_stake {
  float: right;
  margin-left: 10px;
  font-weight: bold;
  margin-top: 2px;
}
._slip_total_stake span._slip_date {
  float: right;
  margin-left: 20px;
  font-weight: normal;
}
.betslipWrapper .card-header {
  padding: 5px;
}
._gr_btn_innder {
  display: flex;
  align-items: center;
}
._gr_btn_inner span {
  font-weight: bold;
  vertical-align: text-top;
}
.cright {
  text-align: right;
  font-weight: bold;
}
._cbody_wrapper {
  border-bottom: 1px solid var(--border-color);
}
span.odds-increase {
  color: var(--red);
}
span.odds-decrease {
  color: var(--green);
}
._slip_to_return {
  font-weight: normal;
}
._slip_cashout {
  border-top: 1px solid var(--shade-border-color);
  margin-top: 20px !important;
  padding-top: 10px;
}

/** hide cashout in full page **/
button._slip_fp_co.active._full_pg_co {
  display: none;
}

button._slip_co {
  border: 1px solid var(--primary-border-color);
  padding: 6px;
  border-radius: 5px;
  max-width: 200px;
  color: var(--text-color);
  width: 100%;
  background: var(--shade-background);
}
button._slip_co._bcashed {
  color: #a1a1a1;
}

button._slip_co span {
  margin-left: 10px;
  font-weight: bold;
}
button._slip_co.active span {
  color: var(--light-green);
}

button._slip_co.active:hover {
  background-color: var(--hover-color);
}

._mobile-down-nav .active,
._top-content-nav-list .active {
  color: #05a271;
}

button._slip_co._slscore {
  background: #d8f5da;
}
button._slip_co._slscore a {
  color: black;
}

button._slip_co._slcancel {
  background: red;
  color: white;
}

.betslipWrapper .card-header button.slipBtn {
  background: var(--primary-color);
  border: none;
  border-radius: 2px;
}
.betslipWrapper .card {
  background: var(--background-color);
  color: var(--text-color);
}
.betslipWrapper a {
  color: var(--light-green);
}

/*** ADMIN ***/
.response-message {
  position: absolute;
  color: var(--success-color);
  padding: 1em;
  border-radius: 8px;
  text-align: center;
  z-index: 1;
  top: 100px;
  left: 10%;
  background: var(--success-bg);
  margin: 0 auto;
  display: table;
  border-radius: 5px;
  transform: rotate(-30deg);
}
.response-message.error {
  color: var(--error-color);
  background: var(--error-bg);
}
.d-flex {
  float: right;
  margin-bottom: 5px;
}
._options_name {
  float: left;
  margin-top: 6px;
  position: absolute;
  max-width: 70%;
}

/*risk management form*/
form._riskmgt span {
  font-size: 13px;
}
._settingsForm .row {
  width: 100%;
}

/*financials*/
._finslipBtn {
  width: 100%;
}
img._qr-ss {
  max-width: 40px;
}
._fin_row_wrapper {
  display: flex;
  justify-content: space-between;
}

._icon_status,
._collapsible-icon {
  flex: 0.05; /* 5% of the row width */
}

._fin_content_items {
  display: flex;
  justify-content: space-between;
  flex: 0.9; /* 90% of the row width */
}

._fin_content_items > span {
  flex: 1; /* each span element takes an equal share of the _fin_content_items width */
}
span._fin_span_left {
  text-align: left;
}
._fin_body,
._fin-card-header {
  padding: 10px !important;
  font-size: 90%;
}
._finComment {
  font-size: 12px;
  font-weight: normal;
}

/* Admin user list */
._user-admin-rows {
  margin: 0 auto;
  font-family: "Roboto", sans-serif;
}

._user-admin-rows a {
  text-decoration: none;
  color: inherit;
}

._user-admin-rows .row {
  padding: 5px;
  margin: 5px 0;
  border-left: 5px solid #f00 !important;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  transition: background-color 0.3s;
  line-height: 18px;
  font-size: 90%;
  width: 100%;
  overflow: auto;
}

._user-admin-rows .row:hover {
  background-color: var(--hover-color);
}

._user-admin-rows span {
  font-weight: bold;
  display: block;
}

._user-admin-rows div {
  margin-bottom: 5px;
  padding: 0px;
}

._user-first-row.row {
  padding-top: 15px;
}

._user-fname {
  font-size: 20px;
  color: #009688;
  font-weight: bold;
}
._inner-user-info._odd .row {
  color: #110b00;
}
span._text-left {
  float: left;
  padding-top: 10px;
}
button._manage-balance-button {
  float: right;
}
._users-manage-balance {
  text-align: center;
  border-top: 1px solid var(--border-color);
  padding-top: 10px;
}
._bal-manager {
  border-top: 1px solid;
  color: #8f0000;
}
button._deposit-balance {
  position: absolute;
  left: 0;
}
.input-group-btransfer {
  display: flex;
  align-items: center;
}

.input-group-btransfer button {
  margin: 0 10px;
}

.input-group-btransfer input {
  width: 160px;
  border: 1px solid rgba(130, 130, 130, 0.523);
  border-radius: 5px;
}

.input-group-btransfer select {
  margin-left: 10px;
}
.transfer-response._res- {
  margin-top: 20px;
}

/** admin user edit page **/
form._user_edit_admin label {
  margin-bottom: 2px;
  margin-top: 10px;
  padding-left: 5px;
}
.custom-navbar {
  height: 60px;
}

/* page edits */
._editPage .edit-form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

._editPage .edit-form label {
  font-weight: bold;
}

._editPage .edit-form button {
  margin-top: 20px;
}

/** for chat **/
._chatContainer {
  margin: 0px;
  padding: 0px !important;
}

._chatlist_msg {
  font-size: 11px;
  line-height: 12px;
}
._chatContainer .col-8 {
  padding: 0;
}

.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 80vh;
}

._chat_form {
  position: sticky;
  bottom: 0;
}
span._chat_ts {
  margin-left: 10px;
  font-size: 9px;
}
b._chat-self {
  color: #9e9d24;
}
._chatContainer .searchBarContainer i {
  left: 5px;
}
._chatContainer #searchBox {
  padding-left: 24px !important;
}
._chat_search_result_wrapper {
  padding: 5px;
}
._chat_list_ts {
  font-size: 9px;
}
._chatlist_msg.unreadMessage {
  font-weight: bold;
}
.list-group {
  max-height: 100vh;
  overflow: auto;
  padding-bottom: 100px;
}
span._usr_role {
  font-size: 8px;
  font-family: cursive;
  position: absolute;
}

.chat-container {
  position: relative;
  height: 100%;
}

.chat-navbar {
  position: absolute;
  top: 0;
  width: 100%;
  height: 56px;
}

._chat_content {
  overflow-y: auto;
  height: calc(100vh - 32vh);
  padding: 10px;
}

._chat-form-row.row {
  position: fixed;
  bottom: 10px;
  width: 80%;
  padding: 10px;
}

/** gallery **/
.img-container {
  width: 100%;
  padding-bottom: 75%; /* For a 4:3 aspect ratio, adjust as needed */
  position: relative;
}

.card-img-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will make the image cover the full container */
}

/** settings form **/
._settingsForm label {
  font-weight: bold;
}

._settingsForm input {
  font-size: 13px;
  /* background: var(--shade-background) !important; */
  color: var(--text-color);
}
._settingsForm input:hover {
  color: var(--text-color) !important;
}
._profile-settings {
  padding-top: 20px;
}

._profile-settings input {
  font-size: 13px;
  background: var(--shade-background) !important;
  color: var(--text-color);
  border-color: #555;
}
._profile-settings .card {
  border-color: #2b2a2a;
}
._profile-settings .card-body {
  background: var(--loader-border-color);
  color: var(--text-color);
}
form._riskmgt._settingsForm {
  padding: 10px;
}
input#chips,
input#bonus {
  background: transparent !important;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #7cc529;
}

/** live or inplay animation **/
@keyframes pulse {
  0% {
    opacity: 0;
    width: 0;
    height: 0;
  }
  100% {
    opacity: 1;
    width: 30px;
    height: 30px;
  }
}

.liveDot {
  background: #ff0000;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  margin-left: 10px;
  margin-top: 6px;
}

.liveDot::after {
  content: "";
  background: rgba(255, 0, 0, 0.1);
  display: block;
  position: absolute;
  top: -10px;
  left: -10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

/** inplay/prematch sports bar **/
span._sp-count {
  margin-left: 3px;
}
.badge.rounded-circle {
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  background: var(--primary-color);
}
.sportContentContainer {
  display: flex;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #363636;
}

span._league_icon {
  float: left;
  margin-top: -2px;
  font-size: 80%;
}
span._susOdd {
  margin-top: -4px;
}
.sport__league {
  color: #ebeeed;
  display: flex;
  flex-basis: 60%;
  justify-content: flex-start;
  font-size: 13px;
  letter-spacing: 1px;
  position: relative;
  z-index: 1;
  padding: 7px 0px;
  line-height: 17px;
  padding-left: 5px;
}

.sport__league::after {
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  bottom: 0;
  width: 110%;
  background-color: inherit;
  transform: skewX(20deg);
  z-index: -1;
}

._region-name {
  text-align: center;
  line-height: 16px;
  display: table;
  margin: 0 auto;
  border-radius: 3px;
  font-size: 12px;
  padding: 0px 5px;
  min-width: 80px;
}
b._league-region {
  color: var(--secondary-text-color);
}

/*
._region-name {
  display: block;
  float: left;
  position: absolute;
  z-index: 2;
  margin-top: 30px;
  font-size: 12px;
  margin-left: 18px;
}
*/

/*****************************************
for top content nav list
******************************************/
._top-content-nav-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Oswald, Arial, sans-serif;
  line-height: 14px;
  margin: 0px 5px;
}

._top-content-nav-list > div {
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

._top-content-nav-list > div:hover {
  background-color: #f5f5f5;
}
._top-content-nav-list p {
  margin-bottom: 5px;
  text-align: center;
}
._top-content-nav-list a {
  color: #667a67;
}

._top-content-nav-list span {
  font-size: 10px;
  text-align: center;
  display: block;
}

._top-content-nav-list p {
  font-size: 11px;
  font-weight: bold;
}

._top-content-nav-list svg {
  font-size: 20px;
}

._top-content-nav-list > div {
  display: inline-block;
  border-right: 1px solid #363636;
  box-shadow: 1px 0px 1px rgb(87 87 87 / 50%);
}

._top-content-nav-list > div:last-child {
  border-right: none;
  box-shadow: none;
}
._top-content-nav-list > * {
  flex: 1;
}
._top-content-nav-list > div:hover {
  background: var(--shade-background);
}

._topnav-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--shade-background);
  transition: background-color 0.3s;
}

.header-sub-navigation {
  display: flex;
  justify-content: space-around;
  max-width: 360px;
  padding: 7px 10px;
}

._nav-sub-menu-items {
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--primary-border-color);
  padding-bottom: 10px;
}

._nav-sub-menu-items .menu-item {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 5px;
}

._nav-sub-menu-items .menu-item a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

._nav-sub-menu-items .menu-item a span {
  margin-top: 8px;
}

.mobile-nav-sub-menu-items {
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--primary-border-color);
  padding: 20px 10px;
  font-size: 13px;
}

.mobile-nav-sub-menu-items .menu-item {
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 5px;
}

.mobile-nav-sub-menu-items .menu-item a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-nav-sub-menu-items .menu-item a span {
  margin-top: 8px;
}
._mobile-view-sports-nav .searchBarContainer {
  width: 75%;
  padding: 0px 5px;
}
.nav-item {
  flex: 1;
  text-align: center;
}
button._btn_mobile-nav {
  padding: 0;
  margin: 0;
  font-size: 24px;
  margin-top: -5px;
}
button._back-b365-sub {
  float: left;
  font-size: 22px;
}

button._btn_mobile-nav span._mobile-navigation-text {
  font-size: 16px;
}

.layout-header-wrapper {
  color: var(--main-nav-color);
}

/* mobile sports menu */
._mobile-view-sports-nav {
  position: fixed;
  max-width: 560px;
  width: 85%;
  left: 0;
  background: var(--sub-nav);
  z-index: 19991;
  top: 0;
  height: 100%;
  overflow: auto;
  padding-top: 20px;
}
._overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust color and opacity to your preference */
  z-index: 8; /* Assuming the sports menu has a higher z-index */
}

span._th-left {
  padding-right: 10px;
  border-right: 1px solid;
  margin-right: 10px;
}
._tcv-container {
  display: flex;
  align-items: center;
  background: var(--light-league-bg);
  padding: 7px;
  margin-top: 10px;
  color: #8288ab;
}
._tcv-icon {
  flex: 0 0 5%;
  margin-top: -4px;
  margin-right: 3px;
}
._tcv-league {
  flex: 0 0 65%;
}
._tcv-region {
  flex: 0 0 30%;
  text-align: right;
  padding-right: 5px;
  font-size: 12px;
  font-weight: bold;
}
._tcv-event-container {
  margin: 10px;
}
.top-nav-filter svg {
  margin-right: 5px;
  font-size: 18px;
}

/**************************************************
*************************************************/
/*game page or full event view */
.statsContainer {
  margin-top: -64px;
  overflow: hidden;
}
.gamepage__tab__item.active {
  border-bottom: 2px solid #080908;
  color: var(--text-color);
}

ul._market_view_tab {
  z-index: 9;
  background: var(--background-color);
  border-bottom: none;
}
button#_marketview-tab-markets {
  border-radius: 0px;
  border: none;
  border-bottom: 0px;
}
._market_view_tab .nav-item .nav-link.active {
  border-bottom: 5px solid #080908 !important;
  width: 100%;
  background: transparent;
  color: var(--text-color);
  border: none;
}
._market_view_tab.nav-tabs .nav-link {
  width: 100%;
  color: var(--secondary-color);
}
._mainlayout .navbar {
  height: 48px;
  z-index: 10;
  position: sticky;
  top: 0;
  width: 100%;
  /*padding-top: 25px;*/
  overflow-y: hidden;
}

.collapse__content__container {
  border-bottom: 1px solid #332d2d;
  margin-right: 5px;
}
button._slip_fp_co {
  padding: 3px;
  margin-left: 10px;
  border-radius: 5px;
  font-size: 15px;
  background: var(--shade-background);
}
span._co_option_odds {
  font-weight: bold;
  padding-left: 3px;
  color: var(--light-green);
}
span.cashtxt {
  color: #a4a4a4;
}

/**Betslip **/

._termsFooter {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 4px;
}

/* top header navigation */
#themeWrapper .navbar-brand {
  color: var(--text-color);
}
._mainlayout .navbar {
  background-color: var(--shade-background) !important;
}
.navbar-nav a.nav-link {
  color: var(--text-color) !important;
}
button._nav_icon {
  float: right;
  padding-bottom: 10px;
  padding-right: 0px;
  font-size: 32px;
}
._nav_username {
  font-size: 12px;
}
._nav_balance {
  font-weight: bold;
  position: relative;
  font-size: 12px;
  line-height: 5px;
  padding-bottom: 10px;
}
._nav_left_text {
  line-height: 16px;
}

/* menu inside */
.menu__container {
  color: var(--text-color);
  background: var(--background-color);
}
.menu__container a {
  color: var(--secondary-color);
}
.flex-container {
  border-color: var(--border-color) !important;
}
.menu__item__text__title {
  color: var(--text-color);
}

/* for bank page */
._bank .nav-item {
  width: 50%;
}
.not-verified-link {
  padding: 10px;
  background: #d14358;
  margin-top: 10px;
  border-radius: 5px;
}
.not-verified-link {
  padding: 10px;
  background: #ffd8dd;
  margin-top: 10px;
  border-radius: 5px;
  color: #333;
}

.not-verified-link a {
  background: #f00;
  padding: 5px 20px;
  border-radius: 5px;
}
svg._hidewf {
  float: right;
  margin-top: -40px;
  font-size: 50px;
  color: #f00;
  cursor: pointer;
}
.icon-text {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}
._bank-header {
  border-left: 3px solid;
  padding: 0px 10px;
  margin: 20px 9px;
  line-height: 0px;
}

.bankComponent {
  margin-top: 15px;
}
.bankComponent button {
  border: none;
  padding: 7px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 5px;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
}

.bankComponent .tabs button.active {
  /* background-color: #008cba; 
  color: white; */
  border-bottom: 5px solid #008cba;
}

img._qr-code.slactive {
  border: 3px solid #9bab00;
}

.bankComponent button:not(.active):hover {
  background-color: #008cba; /* Blue */
  color: white;
}

.bankComponent .slider-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 280px;
}
.bankComponent .slider {
  background-color: #ff7721;
}
.bankComponent label.switch {
  margin: 0;
}
._bank a.active.nav-link {
  background: #008cba;
  color: #cdeaff;
}
._warning-alert {
  margin-top: 10px;
  background: #3c3c3c;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #016976;
}
._warning-alert svg {
  margin-top: -5px;
}
.dep_agent_op {
  font-size: 12px;
  color: #ffb971;
  margin-bottom: 10px;
}
.warning_text_info span.alert-link {
  color: #5dc362;
}
._deposit-form-container._locdep button {
  color: #b9b9b9;
}
._warning-alert._acrypto {
  margin-top: 10px;
  background: #3c2e00;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #b0781f;
}
.trc_payments {
  padding: 10px;
  background: #363636;
}

.trc_payments span {
  color: #ff9800;
}

.trc_payments p {
  color: #ababab;
}
span.trc_txt {
  font-size: 14px;
  color: #fff;
}
._pm_manual {
  padding: 10px;
  border: 1px solid #525252;
  background: #3e3e3e;
}
span._acc_pm {
  font-size: 14px;
  color: #008107;
}
._deposit-form-container ._deposit-dropdown {
  margin-top: 30px;
  border: 1px solid;
  color: var(--primary-color);
  background: transparent;
}
._payment-details span {
  display: block;
  font-size: 12px;
  font-weight: bold;
}
._pay-row-selected.alert {
  background: #818181;
  color: #fff;
  border: 1px solid #8e8e8e;
}
._pay-row-selected .copy-icon {
  color: #333;
}
._deposit-form-p {
  padding: 0px 10px;
  color: #f00;
}
._am-right-username {
  margin-bottom: 10px;
  color: #760e03;
}
._wi_form.close-form-button {
  background: #f00;
}
._pay-row:hover {
  cursor: pointer;
  background: #7f7b7b;
  border: 1px solid #969393;
}
._pay-row-selected svg.selected-icon {
  float: right;
  margin-top: -20px;
  font-size: 32px;
  color: #ffffff;
}
._img_right_qr {
  text-align: right;
}
img._qr-code {
  max-width: 140px;
  max-height: 140px;
}
input._bank-account {
  border: none;
  overflow: scroll;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
}
._payment-details > div {
  padding: 7px;
}
._payment-details .row {
  line-height: 16px;
  margin-bottom: 10px;
}
._bank-account.highlighted {
  border: 1px solid green;
  background-color: lightgreen;
}
._payment-details .copy-icon {
  position: absolute;
  top: 70%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
}
._payment-details ._secondary-text {
  margin-top: 10px;
}

._payment-details ._secondary-text {
  background: #fff3cd;
  border: 1px solid #ffecb5;
  color: #664d03;
}

._payment-details ._tertiary-text {
  margin-top: 10px;
}

._payment-details ._tertiary-text {
  background: #d1e7dd;
  border: 1px solid #badbcc;
  color: #0f5132;
}
button._deposit-submit-form {
  margin-top: 10px;
  color: #fff !important;
}
button._submit-button {
  margin-top: 10px;
  width: 100%;
}
form._dp-pop-form label {
  margin: 0;
  margin-top: 7px;
  font-size: 13px;
  font-weight: bold;
}
.bankComponent .subtabs button {
  padding: 5px 15px;
  border: 1px solid;
  border-radius: 30px;
  font-size: 13px;
}
.bankComponent .subtabs button.active {
  background: #008cba;
  color: #fff;
}
.bankComponent .subtabs {
  display: flex;
  direction: row;
  overflow-x: auto;
  white-space: nowrap;
}
._pright {
  text-align: right;
}
._payment_history_row {
  border: 1px solid #5e5e5e;
  border-left: 3px solid #a06e1f;
  background: #3d4645;
  margin: 0px;
  margin-bottom: 10px;
  padding: 5px 0px;
  font-size: 12px;
  word-wrap: break-word;
}
._depositHistory ._payment_history_row.row {
  border-left: 3px solid #00796b;
}
._pright b {
  font-size: 20px;
  color: #7ea74e;
}
._wi_form {
  /* margin-top: 50px; */
  /* display: block; */
  background: #fff;
}
._bankForm {
  margin-bottom: 50px;
}
._with-title {
  font-size: 20px;
  color: #bf0000;
  margin-bottom: 5px;
  font-weight: bold;
}
._warning-alert_with {
  margin-top: 20px;
}
span.slide_text {
  color: #000;
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
}
.status.pending {
  color: #f00;
}
.status.completed {
  color: #17d1c6;
}
._withdrawalcomp .subtabs button.active {
  background: #f8d7da;
  color: #842029;
}

._withdrawal-alert .alert-primary,
._withdrawalcomp .alert-primary {
  background: #ffeaec;
  color: #842029;
  border-color: #f5c2c7;
}
._withdrawal-form-inner {
  border: 1px solid var(--border-color);
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
}

.centered-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-dialog {
  margin: 0;
  height: 100%;
}
.centered-modal button.btn.btn-primary {
  width: 100%;
  background: #4caf50;
}
.alert.alert-success.restype_derror {
  background: #ffeaec;
  margin-top: 10px;
}

span._avl-bal {
  font-size: 24px;
  color: #00a994;
}

.image-grid-container {
  display: grid;
  grid-template-columns: repeat(3, calc(33.333% - 6px));
  gap: 10px;
  margin-top: 20px;
}

.image-grid-item img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  cursor: pointer;
  border: 1px solid #4b864d;
  padding: 10px;
}

/** bottom navigation **/
nav.down-nav a {
  color: var(--down-nav-color);
}
nav.down-nav {
  background-color: #333;
}
nav.down-nav a.bulged-link {
  position: relative;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  margin-top: -24px;
}

nav.down-nav a.bulged-link svg {
  border: 2px solid #444;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  padding: 10px;
  background: #14805e;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15);
  color: #fff;
}

/***********************************
*********** casino slot ****************/
._casino-page {
  max-width: 680px;
}
._caff-gaming img {
  width: 100%;
  max-height: 140px;
  height: 100%;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20%;
  width: 100%;
  height: 100%;
}
.game-iframe-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2001;
}
._casinogaming {
  font-size: 24px;
}

.close-frame {
  position: fixed;
  bottom: 10px;
  right: 20px;
  font-weight: bold;
  z-index: 1010;
  background-color: #d50000;
  color: white;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  transform: translateX(50%);
}
._casino-page .alert.alert-info {
  background: var(--light-league-bg);
  color: var(--hover-color);
  border: var(--shade-border-color);
}
a.btn.btn-primary {
  background: var(--primary-color);
  border: 1px solid var(--light-primary-color);
}
._casino-page a.active.nav-link {
  background: #4c4c4c;
  color: white;
  border: 1px solid var(--shade-border-color);
}
._casino-page ul.flex-row.nav.nav-tabs {
  border-bottom: 1p solide var(--secondary-color);
}
._casino-page .card {
  background: var(--background-color);
  color: var(--light-green);
}
/***********Loader**********/
/* Loader.css */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 8px solid var(--loader-border-color);
  border-radius: 50%;
  border-top: 8px solid #23b208;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/***** for no content text ******/
.noResults {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 300px;
  font-family: "Roboto Condensed", sans-serif;
  color: #6d6d6d;
}

.noResults span {
  font-size: 36px;
  margin: 10px 0;
}

.noResults .or {
  font-size: 54px;
  transform: rotate(-15deg);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-15deg);
}

/** event search **/
.searchContainer {
  padding: 10px;
}

.searchContainer a {
  color: var(--light-green);
}
.searchContainer .border {
  border: none !important;
  border-bottom: 1px solid var(--primary-color) !important;
}
/*password reset*/
.linksent {
  padding: 5px 10px;
  background: #c5e1a5;
  margin-bottom: 10px;
  border-radius: 2px;
}

/** footer **/
.footer-inner-wrapper {
  padding: 10px;
  border-top: 1px solid var(--shade-border-color);
}
